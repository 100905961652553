<template>
  <td :colspan="headerLength" class="py-5 pl-16">
    <div class="question" v-html="item.question" @click="viewImage" />

    <!-- start question file -->
    <v-row v-if="item.question_file[0]">
      <v-col v-if="item.question_file.length === 1">
        <v-img
          v-if="validateImage(item.question_file[0])"
          :src="item.question_file[0]"
          style="cursor: pointer"
          max-height="200px"
          max-width="300px"
          @click="viewImage(item.question_file[0])"
        />
        <v-chip
          v-else
          color="primary"
          @click="viewImage(item.question_file[0])"
        >
          {{ item.question_file[0].replace(/^.*[\\/]/, "") }}
        </v-chip>
      </v-col>
      <div v-else v-for="file in item.question_file" :key="file" class="ma-2">
        <v-chip @click="viewImage(file)" color="primary">
          {{ file.replace(/^.*[\\/]/, "") }}
        </v-chip>
      </div>
    </v-row>
    <!-- end question file -->

    <!-- start multiple choice -->
    <v-row>
      <v-col
        v-for="data in item.pg"
        :key="data.id"
        cols="12"
        sm="6"
        class="my-2"
      >
        <div :class="!data.key || 'font-weight-bold'" class="d-flex flex-row">
          <span class="mr-2">{{ data.code }}.</span>
          <span v-html="data.answer" class="answer" @click="viewImage" />
        </div>
        <v-row v-if="data.answer_file[0]">
          <v-col v-if="data.answer_file.length === 1">
            <v-img
              v-if="validateImage(data.answer_file[0])"
              :src="data.answer_file[0]"
              style="cursor: pointer"
              max-height="200px"
              max-width="300px"
              @click="viewImage(data.answer_file[0])"
            />
            <v-chip
              v-else
              color="primary"
              @click="viewImage(data.answer_file[0])"
            >
              {{ data.answer_file[0].replace(/^.*[\\/]/, "") }}
            </v-chip>
          </v-col>
          <div v-else v-for="file in data.answer_file" :key="file" class="ma-2">
            <v-chip color="primary" @click="viewImage(file)">
              {{ file.replace(/^.*[\\/]/, "") }}
            </v-chip>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <!-- end multiple choice -->

    <!-- start answer -->
    <div v-if="item.esay">
      <v-divider class="my-3" />
      <span class="font-weight-bold">{{ $t("e_test.answer") }}</span>
      <span v-html="item.esay.key" />
      <v-row v-if="item.esay.key_file[0]">
        <v-col v-if="item.esay.key_file.length === 1">
          <v-img
            v-if="validateImage(item.esay.key_file[0])"
            :src="item.esay.key_file[0]"
            style="cursor: pointer"
            max-height="200px"
            max-width="300px"
            @click="viewImage(item.esay.key_file[0])"
          />
          <v-chip
            v-else
            color="primary"
            @click="viewImage(item.esay.key_file[0])"
          >
            {{ item.esay.key_file[0].replace(/^.*[\\/]/, "") }}
          </v-chip>
        </v-col>
        <div v-else v-for="file in item.esay.key_file" :key="file" class="ma-2">
          <v-chip @click="viewImage(file)" color="primary">
            {{ file.replace(/^.*[\\/]/, "") }}
          </v-chip>
        </div>
      </v-row>
    </div>
    <!-- end answer -->

    <!-- start discussion -->
    <v-divider class="my-3" />
    <span class="font-weight-bold">{{ $t("e_test.explain") }}</span>
    <div v-if="item.type === 'pg'">{{ item.discussion || "-" }}</div>
    <div v-else>
      <span v-html="item.discussion" />
    </div>
    <!-- end discussion -->
  </td>
</template>

<script>
import { isImageFile } from "@/utils/validate";

export default {
  props: {
    headerLength: Number,
    item: Object
  },
  methods: {
    validateImage: name => isImageFile(name),
    viewImage(item) {
      const img = typeof item === "string" ? item : item.target.src;
      if (img) {
        this.$emit("viewImage", img);
      }
    }
  }
};
</script>
